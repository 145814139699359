angular
    .module('annexaApp')
    .component('annexaTaskTypeStatuses', {
        templateUrl: './components/admin/annexa-task-type-statuses/annexa-task-type-statuses.html',
        controller: ['$rootScope', 'Language', '$filter', 'RestService', function($rootScope, Language, $filter, RestService) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            vm.requiredText = $filter('translate')('global.validation.required');
            vm.inserted = undefined;

            vm.globalStatuses = [
                { id: 'PENDING', name: 'global.literals.PENDING' },
                { id: 'IN_PROGRESS', name: 'global.literals.IN_PROGRESS' },
                { id: 'COMPLETED', name: 'global.literals.COMPLETED' },
                { id: 'CANCELLED', name: 'global.literals.CANCELLED' }
            ];

            vm.updateStatus = function (rowformtaskstatus) {
                _.forEach(vm.type.statuses, function(value, key) {
                    var languageNull = true;

                    _.forEach($rootScope.app.languagedef, function(val) {
                        if(value[val.column]) {
                            languageNull = false;
                        }
                    });

                    if(languageNull) {
                        vm.type.statuses.splice(key, 1);
                    }
                });
            }

            vm.removeStatus = function(index) {
                if(!vm.isNew) {
                	var statusToRemove = vm.type.statuses[index];
                    RestService.delete('./api/task_type_status/' + vm.type.statuses[index].id)
                        .then(function(data) {
                            vm.type.statuses.splice(index, 1);
                        	$rootScope.$broadcast('AnnexaTaskTypeStatusDeleted', { item: statusToRemove });
                        }).catch(function(error) {
                            //Empty
                    });
                } else {
                    vm.type.statuses.splice(index, 1);
                }
            }

            vm.addStatus = function() {
                vm.inserted = {
                    language1: undefined,
                    language2: undefined,
                    language3: undefined,
                    acronym: undefined,
                    defaultValue: false,
                    orderView: 0
                }

                if(!vm.isNew) {
                    vm.inserted.type = vm.type;
                }

                vm.type.statuses.push(vm.inserted);
            }

            vm.editStatus = function (index) {
                //Empty
            }

            vm.checkName = function ($data, status, object) {
                var languageOk = true;

                _.forEach($rootScope.app.languagedef, function(val) {
                    if(!$data[val.column]) {
                        languageOk = false;
                    }
                });

	        	if(status.orderView == 0 && vm.type.statuses && vm.type.statuses.length > 0){
	        		status.orderView = $linq(vm.type.statuses).select("x=>x.orderView").max()+10;
	        	}

                if(!languageOk) {
                    return vm.requiredText;
                } else {
                    status = $data;

                    if(vm.isNew) {
                        $rootScope.$broadcast('AnnexaTaskTypeStatusAdded', { statuses: vm.type.statuses });
                    }
                }
            }

            vm.checkAcronym = function ($data, status, object) {
            	if (!$data) {
            		return vm.requiredText;
            	} else {
                    status.acronym = $data;

                    if(vm.isNew) {
                        $rootScope.$broadcast('AnnexaTaskTypeStatusAdded', { statuses: vm.type.statuses });
                    }
            	}
            }

            vm.checkDefault = function ($data, status, object) {
                status.defaultValue = $data;

            	if (status.defaultValue) {
                	var defaultStatus = $linq(vm.type.statuses).firstOrDefault(undefined, "x => x.defaultValue == true && x.orderView != " + status.orderView);
                	if (defaultStatus) {
                		defaultStatus.defaultValue = false;
                	}
            	}
            	
                if(vm.isNew) {
                    $rootScope.$broadcast('AnnexaTaskTypeStatusAdded', { statuses: vm.type.statuses });
                }
            }

            vm.checkGlobalStatus = function ($data, status, object) {
            	if (!$data) {
            		return vm.requiredText;
            	} else {
                    status.globalStatus = $data;

                    if(vm.isNew) {
                        $rootScope.$broadcast('AnnexaTaskTypeStatusAdded', { statuses: vm.type.statuses });
                    } else {
                        $rootScope.$broadcast('AnnexaTaskTypeStatusUpdated', { status: status });
                    }
            	}
            }

            vm.printYesNo = function (value) {
                if(value == true) {
                    return '<i class="fa fa-check text-success"></i>';
                } else {
                    return '<i class="fa fa-remove text-danger"></i>';
                }
            }
        }],
        bindings: {
            type: '=?',
            isNew: '='
        }
    })